import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import { AppStateContext } from "../contexts/AppStateContext";
import LayoutLoader from "../components/layout"

const LogoutPage = () => {

    const appState = useContext(AppStateContext);
    useEffect(() => {
      appState.profileUuid.set("")
      appState.sessionId.set("")
      appState.paymentPage.set(true)
      appState.loggedIn.set(false)
      appState.plan.set('basic')
      appState.planUpgrade.set(false)
      navigate('/')
    }, [])



    return <LayoutLoader/>
}

export default LogoutPage
